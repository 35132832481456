const page = '/p';
const admin = '/a';

const query = (params: Record<string, string | undefined>) => {
  const searchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (value !== undefined) searchParams.set(key, value);
  }
  return searchParams.size > 0 ? '?' + searchParams.toString() : '';
};

export const paths = {
  landing: '/waitlist',
  landingSignup: '/waitlist/signup',
  earlyAccess: '/early-access',
  makeEarlyAccess: (email?: string) => `/early-access${query({ email })}`,

  privacyPolicy: '/policy',
  termsOfUse: '/terms',
  default: '/',
  xAuth: '/xauth',

  login: '/auth/login',
  makeLogin: (from?: string) => `/auth/login${query({ from })}`,
  signup: '/auth/signup',
  makeSignup: (from?: string, code?: string) =>
    `/auth/signup${query({ from, code })}`,
  resetPassword: '/auth/reset-password',
  makeResetPassword: (from?: string) =>
    `/auth/reset-password${query({ from })}`,

  invite: `${page}/invite`,
  makeInvite: (code: string, invitedBy?: string) =>
    `${page}/invite${query({ code, invitedBy })}`,

  user: '/:username',
  makeUser: (name: string) => `/${name}`,
  userStreams: `/:username/streams`,
  makeUserStreams: (name: string) => `/${name}/streams`,
  followers: `/:username/followers`,
  makeFollowers: (name: string) => `/${name}/followers`,
  following: `/:username/following`,
  makeFollowing: (name: string) => `/${name}/following`,

  post: `${page}/posts/:id`,
  makePost: (postId: string, commentId?: string) =>
    `${page}/posts/${postId}${query({ commentId })}`,
  postReplies: `${page}/posts/:id/replies/:commentId`,
  makePostReplies: (
    postId: string,
    parentCommentId: string,
    commentId?: string,
  ) =>
    `${page}/posts/${postId}/replies/${parentCommentId}${query({ commentId })}`,

  tournaments: `${page}/tournaments`,
  makeTournaments: (category: string) =>
    `${page}/tournaments?category=${category}`,
  activeTournaments: `${page}/tournaments/active`,
  makeActiveTournaments: (category: string) =>
    `${page}/tournaments/active?category=${category}`,
  closedTournaments: `${page}/tournaments/closed`,
  makeClosedTournaments: (category: string) =>
    `${page}/tournaments/closed?category=${category}`,
  tournament: `${page}/tournaments/:id`,
  makeTournament: (id: string) => `${page}/tournaments/${id}`,
  submissions: `${page}/tournaments/:id/submissions`,
  makeSubmissions: (id: string) => `${page}/tournaments/${id}/submissions`,
  questionnaire: `${page}/tournaments/:id/questionnaire`,
  makeQuestionnaire: (id: string) => `${page}/tournaments/${id}/questionnaire`,
  participants: `${page}/tournaments/:id/participants`,
  makeParticipants: (id: string) => `${page}/tournaments/${id}/participants`,
  discussions: `${page}/tournaments/:id/discussions`,
  makeRules: (id: string) => `${page}/tournaments/${id}/rules`,
  rules: `${page}/tournaments/:id/rules`,
  makeDiscussions: (id: string) => `${page}/tournaments/${id}/discussions`,

  streams: `${page}/streams`,
  makeStreams: (category: string) => `${page}/streams?category=${category}`,
  followingStreams: `${page}/streams/following`,
  makeFollowingStreams: (category: string) =>
    `${page}/streams/following?category=${category}`,
  followingStreamPosts: `${page}/streams/following/subscriptions`,
  makeFollowingStreamPosts: (category: string) =>
    `${page}/streams/following/subscriptions?category=${category}`,
  createdStreams: `${page}/streams/created`,
  makeCreatedStreams: (category: string) =>
    `${page}/streams/created?category=${category}`,
  paidStreams: `${page}/streams/paid`,
  makePaidStreams: (category: string) =>
    `${page}/streams/paid?category=${category}`,
  stream: `${page}/streams/:id`,
  makeStream: (id: string) => `${page}/streams/${id}`,
  streamDiscussions: `${page}/streams/:id/discussions`,
  makeStreamDiscussions: (id: string) => `${page}/streams/${id}/discussions`,
  streamApi: `${page}/streams/:id/api`,
  makeStreamApi: (id: string) => `${page}/streams/${id}/api`,

  notifications: `${page}/notifications`,
  leaderboard: `${page}/leaderboard`,
  search: `${page}/search`,
  makeSearch: (q?: string, type?: string) =>
    `${page}/search${query({ q, t: type })}`,

  inviteFriend: `${page}/invite-friend`,

  settings: `${page}/settings`,
  settingsPersonalDetails: `${page}/settings/personal-details`,
  settingsConnectedAccounts: `${page}/settings/connected-accounts`,
  settingsSecurity: `${page}/settings/security`,
  settingsPassword: `${page}/settings/security/password`,
  settingsPayouts: `${page}/settings/payouts`,
  settingsApiKeys: `${page}/settings/api-keys`,

  notificationsSettings: `${page}/settings/notifications/all`,
  notificationsSettingsEmail: `${page}/settings/notifications/email`,
  notificationsSettingsInApp: `${page}/settings/notifications/in-app`,
  notificationsSettingsPush: `${page}/settings/notifications/push`,

  streamDeck: `${page}/stream-deck`,
} as const;

export const adminPaths = {
  tournaments: `${admin}/tournaments`,
  tournament: `${admin}/tournaments/:id`,
  makeTournament: (id: string) => `${admin}/tournaments/${id}`,
  invites: `${admin}/invites`,
} as const;
